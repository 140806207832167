import React from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import ACCAPartnerLogo from '../images/acca-gold.png';
import "../scss/main.scss";

export default function Courses({ data: { markdownRemark } }) {

    const { frontmatter, html } = markdownRemark

    return (
        <Layout pageTitle={frontmatter.pageTitle}>
            <section className="container hero" style={{paddingBottom: 0}}>
                {frontmatter.showACCAPartnerLogo && <img
                    alt="ACCA approved Gold Learning Partner"
                    src={ACCAPartnerLogo}
                    placeholder="blurred"
                    className='acca-partner-logo'
                />}
                <br/>
                <br/>
                <h1 className="hero__title">
                    {frontmatter.title}
                </h1>
                <h3 className="hero__subtitle" style={{paddingBottom: '1rem', marginTop: '2rem'}}>
                    {frontmatter.subtitle}
                </h3>
            </section>
            <section>
                <div className="container why-us__container" style={{padding: '2rem 4rem'}}>
                    <div className="markdown-container"
                        dangerouslySetInnerHTML={{ __html: html }}
                    />
                    {frontmatter.author && <h4 style={{float: 'right'}}>~ <a href={frontmatter.linkedin} target="_blank" rel="noreferrer">{frontmatter.author}</a></h4>}
                </div>
              
            </section>
            
        </Layout>
    );
}


export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        pageTitle
        subtitle
        showACCAPartnerLogo
        slug
        author
        linkedin
      }
    }
  }
`;
